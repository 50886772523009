.messenger {
  display: grid;
  width: 350px;
  height: 515px;
  background: #ffffff;
  position: relative;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messengerLoader {
  width: 350px;
  height: 515px;
  background: #ffffff;
  position: relative;
}
.loaderAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  color: rgb(133, 133, 133);;
}

.container {
  padding: 0px;
}

.scrollable-content {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 10px;
  padding-top: 1px;
  padding-bottom: 20px;
  background: rgb(255, 255, 255);
  grid-row-start: 1;
  grid-row-end: span 3;
}


.messageHeader {
  background: rgb(240, 240, 240);
  height: 70px;
}
.chatTitle {
  color: rgb(133, 133, 133);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  bottom: 10px;
}
.transcript-button {
  position: relative;
}
.chatTitle-null {
  color: rgb(133, 133, 133);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 20px;
}

.refresh-button {
  position: relative;
  float: right;
}

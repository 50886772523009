body {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    /* color: white !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}
.message .smallTimestamp {
  color: #999;
  font-weight: 500;
  font-size: 10px;
  margin: 0px 0px;
  text-align: left;
  margin-left: 10px;
}
.message.mine .smallTimestamp {
  color: #999;
  font-weight: 500;
  font-size: 10px;
  margin: 0px 0px;
  text-align: right;
  margin-right: 10px;
}

.message.end .smallTimestamp {
  margin-bottom: 10px;
}
.message.mine.end .smallTimestamp {
  margin-bottom: 10px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  color: black;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 80%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.message.sourceIndicator .bubble-container .bubble {
  background: #8d0000;
  color: white;
}
.message.mine.sourceIndicator .bubble-container .bubble {
  background: #8d0000;
}
.message.start.sourceIndicator .bubble-container .bubble {
  background: #8d0000;
  color: white;
}
.message.mine.start.sourceIndicator .bubble-container .bubble {
  background: #8d0000;
}
.message.end.sourceIndicator .bubble-container .bubble {
  background: #8d0000;
  color: white;
}
.message.mine.end.sourceIndicator .bubble-container .bubble {
  background: #8d0000;
}


.message .name {
  background: rgba(255, 255, 255, 0);
  color: rgb(133, 133, 133);
  font-size: 14px;
  position: relative;
  text-align: left;
  padding: 0px 10px;
}

.message.mine .name {
  background: rgba(255, 255, 255, 0);
  color: rgb(133, 133, 133);
  font-size: 14px;
  position: relative;
  text-align: right;
  top: 7px;

  padding: 0px 10px;
}

.other-media {
  max-width: 80%;
}
.message .other-media {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.message.mine .other-media {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.message.start .other-media {
  margin-top: 10px;
}

.zoom-in-image {
  max-width: 100%;
}
